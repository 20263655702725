import { Show, type Component } from "solid-js";
import { Title, Meta, Link } from "~/lib/solid-meta";

export const SEOHeaders: Component<{
  title?: string;
  description?: string;
  url?: string;
  image?: string;
}> = (props) => {
  return (
    <>
      <Meta property="og:locale" content="en_US" />
      <Meta property="og:site_name" content="Storytell.ai" />
      <Meta property="og:type" content="website" />

      {/* Url */}
      <Show when={props.url}>
        <Meta property="og:url" content={props.url} />
        <Link rel="canonical" href={props.url} />
      </Show>

      {/* Title */}
      <Show when={props.title}>
        <Title>{props.title}</Title>
        <Meta property="og:title" content={props.title} />
        <Meta property="twitter:title" content={props.title} />
      </Show>

      {/* Description */}
      <Show when={props.description}>
        <Meta name="description" content={props.description} />
        <Meta property="og:description" content={props.description} />
        <Meta property="twitter:description" content={props.description} />
      </Show>

      {/* Image */}
      <Show when={props.image}>
        <Meta property="og:image" content={props.image} />
        <Meta property="twitter:image" content={props.image} />
      </Show>

      {/* Twitter card */}
      <Meta property="twitter:card" content="summary" />
    </>
  );
};
